




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FscInput extends Vue {
  public name = "FscInput";

  @Prop()
  public value!: any;
}
